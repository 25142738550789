@import "bootstrap-config";
@import "../vendor/twbs/bootstrap/scss/variables";

// _normalize.scss
button,
input,
optgroup,
select,
textarea{
	font-family: $font-family-base;
}
