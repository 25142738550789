@import "utils";
@import "var/const";

/**
Product window top section
 */

.top-panel{
	margin: 0;
	float: left;
	padding: 0;
	background: $cl-white;
	li{
		float: left;
	}
	@include respond-max-h($menupos-breakdown){
		left: auto;
		right: 0;
	}
}
