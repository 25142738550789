// Scrapped from http://www.wiesner-hager.com/



//@font-face{
//	font-family:"Rockwell W01 Light";
//	//src:url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/a12ca1f2-0a59-469b-83da-6f10f8486594.eot?#iefix);
//	src:
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/a12ca1f2-0a59-469b-83da-6f10f8486594.eot?#iefix) format("eot"),
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/833d7533-fcac-4e86-87d6-1a760611a07b.woff) format("woff"),
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/d7bbf89c-2d47-41a6-8a36-69d44b1c398f.ttf) format("truetype"),
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/deacffdf-d1e1-4603-9b18-e307f659cf0a.svg#deacffdf-d1e1-4603-9b18-e307f659cf0a) format("svg")
//}

@font-face{
	font-family:"Rockwell W01";
	src:
		url(../fonts/Rockwell-W01.woff) format("woff");
// src:url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/ae0e1f8a-f20d-412d-9948-3ac6f314eb3d.eot?#iefix);
// Source URL:
//	src:
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/ae0e1f8a-f20d-412d-9948-3ac6f314eb3d.eot?#iefix) format("eot"),
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/2d1664e5-853a-447f-a8bb-64dcb9e3ba18.woff) format("woff"),
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/20ec3f06-bfa9-4c4d-b9ec-1860d99752a2.ttf) format("truetype"),
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/0a7cfc77-5c20-4a9d-9288-8028d2bf90f2.svg#0a7cfc77-5c20-4a9d-9288-8028d2bf90f2) format("svg");
//
}

//@font-face{
//	font-family:RockwellW01-LightItalic;
//	//src:url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/c98c634a-d860-4d8c-a413-6bea8c090b44.eot?#iefix);
//	src:
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/c98c634a-d860-4d8c-a413-6bea8c090b44.eot?#iefix) format("eot"),
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/366a2052-bf25-4f69-bb51-f493c23bcb39.woff) format("woff"),
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/8004beef-b7b0-41a7-a41e-fdd1a307079b.ttf) format("truetype"),
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/e5b18a00-99af-47d4-9c85-4997ec26c213.svg#e5b18a00-99af-47d4-9c85-4997ec26c213) format("svg")
//}
//
//@font-face{
//	font-family:"Rockwell W01 Italic";
//	//src:url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/522d9502-3918-40d5-80cf-3383e2a65de6.eot?#iefix);
//	src:
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/522d9502-3918-40d5-80cf-3383e2a65de6.eot?#iefix) format("eot"),
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/d948e5e2-c9b0-40ee-bb95-080f787926bd.woff) format("woff"),
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/07beb45c-6bdb-4d7d-8f2a-2d4e66b19d09.ttf) format("truetype"),
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/5eeddef8-8309-43fa-aa70-42c0e3f3b6b7.svg#5eeddef8-8309-43fa-aa70-42c0e3f3b6b7) format("svg")
//}
//
//@font-face{
//	font-family:FrutigerLTW01-95UltraBl;
//	//src:url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/47e272a5-5523-490a-a689-1e4660531849.eot?#iefix);
//	src:
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/47e272a5-5523-490a-a689-1e4660531849.eot?#iefix) format("eot"),
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/3a6c4a0a-2e4b-472c-a995-1a2e93bd1da2.woff) format("woff"),
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/4a66b185-1957-4863-86a8-db4f99ff626e.ttf) format("truetype"),
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/e8e7f894-9084-43e9-b08a-5dcd346ea067.svg#e8e7f894-9084-43e9-b08a-5dcd346ea067) format("svg")
//}

@font-face{
	font-family: FrutigerLTW02-45Light;

//  src:url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/99f761bf-9f30-4bdd-b284-ee7038dc52c9.eot?#iefix);
	src:
		url(../fonts/FrutigerLTW02-45Light.woff) format("woff");
//	src:
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/99f761bf-9f30-4bdd-b284-ee7038dc52c9.eot?#iefix) format("eot"),
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/59d9a83f-4045-4d43-af46-655f845461ee.woff) format("woff"),
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/f901b503-9104-414a-a856-af9bcc802b5c.ttf) format("truetype"),
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/e4d07d96-8993-47b9-9f1d-1e228bf1c529.svg#e4d07d96-8993-47b9-9f1d-1e228bf1c529) format("svg")
}

@font-face{
	font-family:FrutigerLTW02-46LightIt;
//  src:url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/412becac-582a-4ba8-960b-67344a7c4f35.eot?#iefix);
	src:
		url(../fonts/FrutigerLTW02-46LightIt.woff) format("woff");
//	src:
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/412becac-582a-4ba8-960b-67344a7c4f35.eot?#iefix) format("eot"),
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/9df27c27-758a-459d-adef-ec794e2f4339.woff) format("woff"),
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/e32edd56-9f04-4f0b-8ed3-4c2fee583dcf.ttf) format("truetype"),
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/9cf390ea-aabe-4d32-8126-d627ce744f4e.svg#9cf390ea-aabe-4d32-8126-d627ce744f4e) format("svg")
//
}

@font-face{
	font-family:FrutigerLTW02-55Roman;
//  src:url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/c94bd41e-23b3-4522-8237-0a0f7bd0e953.eot?#iefix);
	src:
		url(../fonts/FrutigerLTW02-55Roman.woff) format("woff");
//	src:
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/c94bd41e-23b3-4522-8237-0a0f7bd0e953.eot?#iefix) format("eot"),
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/409b4bec-c67e-4764-a141-054db8df81d2.woff) format("woff"),
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/efe9def0-77d1-4c28-8fd2-371236a3c8ed.ttf) format("truetype"),
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/70b07d32-76f5-474e-83b0-7d5a3fefb15b.svg#70b07d32-76f5-474e-83b0-7d5a3fefb15b) format("svg")
}

//@font-face{
//	font-family:FrutigerLTW02-66BoldIta;
//	//src:url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/02b9986e-456b-4558-a8ad-505610712af0.eot?#iefix);
//	src:
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/02b9986e-456b-4558-a8ad-505610712af0.eot?#iefix) format("eot"),
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/39967461-d5a8-478e-b0ea-71e3a19e6026.woff) format("woff"),
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/8acd4c9f-64ad-413f-a91b-62cab06e39ea.ttf) format("truetype"),
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/901c0cd0-12ab-4cc1-98fa-03352a525e92.svg#901c0cd0-12ab-4cc1-98fa-03352a525e92) format("svg")
//}
//
//@font-face{
//	font-family:"Futura W01 Book";
//	//src:url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/57a6731b-fa5c-4c8a-bea8-e2f1a287f309.eot?#iefix);
//	src:
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/57a6731b-fa5c-4c8a-bea8-e2f1a287f309.eot?#iefix) format("eot"),
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/4d0e252c-d811-4a5d-a7f3-6ad72c6b76f2.woff) format("woff"),
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/54250d43-02be-4ff9-b802-a4ea104a0611.ttf) format("truetype"),
//		url(http://www.wiesner-hager.com/fileadmin/fonts/fontscom_ext/15c556fc-4608-4b01-8a0d-9c8631c8bd74.svg#15c556fc-4608-4b01-8a0d-9c8631c8bd74) format("svg")
//}
