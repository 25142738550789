@import "../../vendor/twbs/bootstrap/scss/variables";
@import 'compass';

//noinspection CssOptimizeSimilarProperties
.loadProgress{
	z-index: 1;
	display: flex;
	flex-wrap: wrap;
	$heightTotal: $spacer * 2 + $progress-height;
	$widthTotal: 500px;
	width: 100%;
	position: absolute;
	bottom: 50%;
	margin: 0 0 (-$heightTotal / 2) 0;
	left: 0;
	height: $progress-height;
	div.progress{
		box-sizing: border-box;
		flex: 0 0 100%;
		max-width: $widthTotal;
		width: 100%;
		background: $white;
		padding: $spacer;
		@include box-shadow();
	}
}
