@import "../../var/brand";
@import "../../var/const";
@import "../../var/layout";
@import "../../utils";
@import "compass/css3";
$property-padding: $padding-general;

$item-border: $wh-active-item-border;

$property-height: 30px;

fieldset#property-listing{
	position: absolute;
	top: $top-menu-offset + $top-menu-size + $layout-padding;
	@include dynamicAlignment();

	width: auto;
	&.open{
		width: 100%;
		max-width: 400px;
	}
	//@include single-transition(opacity, 0.35s, ease-in-out);
	&>legend{
		button{
			font-size: $property-height;
		}
	}
	.fs-content{
		background: $cl-white;
	}
}

fieldset.product-property{
	height: $control-height + $padding-general;
	&.open{
		height: auto;
	}
	&.disabled{
		@include single-transition(opacity, 0.35s, ease-in-out);
		display: none;
	}
	legend, label, span.group-label{
		clear: both;
		margin: 1px 0 0 0;
		padding: $padding-general $property-padding;
		width: 100%;
		cursor: pointer;
		border: none;
		font-weight: normal;
		line-height: $control-height - $padding-general;
		font-size: 1.1rem;
	}
	legend, span.group-label{
		display: block;
	}

	ul{
		list-style: none;
		padding: 0;
		margin: 0;
		li{
			position: relative;
			box-sizing: border-box;
			span.price{
				float: right;
			}
		}
	}
	legend{
		background: $cl-wh-red;
		color: $cl-white;
	}
	span.group-label{
		background: $cl-wh-gray;
		color: $cl-white;
		margin-bottom: 0;
	}
	label{
		//background: $cl-wh-gray;
		background: $cl-menu-background;
	}
	.product-property-options{
		li:not(.option-group){
			label{
				padding-left: $item-border + $property-padding;
				box-sizing: border-box;
			}
			&:hover{
				@include item-hover;
				z-index: 10;
			}
			&.active{
				label{
					padding-left: $property-padding;
					border-left: $item-border solid $cl-wh-red;
				}
			}
		}
	}

	& > .product-property-options,
	li.option-group > .product-property-options{
		//display: none;
		//@include single-transition(max-height, 0, ease-in-out);

		display: block;
		overflow: hidden;
		opacity: 0;
		max-height: 0;
	}
	li.option-group.open,
	&.open{
		& > .product-property-options{
			//@include single-transition(max-height, 1s, ease-in-out);
			@include single-transition(opacity, 0.35s, ease-in-out);
			max-height: 1000px;
			opacity: 1;
			padding-bottom: $property-padding / 2;
		}
	}

	li.option-group.open > span.group-label{
		border-color: $cl-wh-red;
	}
	input.property{
		margin-right: $property-padding;
	}
	&.property-material{
		ul ul.product-property-options{
			height: auto;
		}
		li:not(.option-group){
			position: relative;
			display: block;
			float: left;
			//width: $material-item-size;
			height: auto;
			z-index: 2;
			border: none;
			width: (100% / 6);
			&.active{
				label {
					border: none;
					padding-left: 0;
					img{
						border-color: $cl-wh-red;
					}
				}
			}
			label{
				//padding: $item-border;
				//position: absolute;
				padding: 0 0 100%;
				height: 0;
				margin: 0;
				z-index: 1;
				&,img{
					left: 0;
					top: 0;
					width: 100%;
				}
				img{
					@include box-sizing(border-box);
					position: absolute;
					height: auto;
					z-index: 0;
					border: $item-border solid $cl-white;
				}
				input{
					position: absolute;
					left: $item-padding;
					bottom: $item-padding;
				}
			}
		}
	}

	@import "product-property-previews";
	@import "product-property-scroll";
	@import "property-material";
}
