/**
Styles of GUI shared between application and plugin
 */

$is-plugin: false !default;

@import "../utils";
@import "../var/const";

// This class keeps aspect ratio maxed to screen size)
.view3dCont{
	width: 100%;
	height: 100%;
	overflow: hidden;
	margin: 0 auto;
	@if $is-plugin != true {
		max-width: 100vh;
		max-height: 100vw;
		position: relative;
		@include vertical-layout{
			width: 100%;
			height: auto;
		}
		@include horizontal-layout{
			height: 100vh;
			width: 100vh;
		}
	}
}

.App3D-view{
	//background: $cl-white;
	//background: #f7f7f7 url('../data/textures/checker.png') repeat;
	width: 0;
	height: 100%;
	padding-right: 100%;
	left: 0;
	cursor: move;
	canvas { width: 100%; height: 100% }
	@if $is-plugin != true {
		@include vertical-layout {
			width: 100%;
			height: 0;
			padding-right: 0;
			padding-bottom: 100%;
		}
	}
}

@import "product-property/product-property";
@import "item-select";
@import "ctl";
@import "load-rogress";
