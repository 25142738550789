&.property-material{
	ul ul.product-property-options{
		height: auto;
	}
	li:not(.option-group){
		position: relative;
		display: block;
		float: left;
		//width: $material-item-size;
		height: auto;
		z-index: 2;
		border: none;
		width: (100% / 6);
		&.active{
			label {
				border: none;
				padding-left: 0;
				img{
					border-color: $cl-theme-color;
				}
			}
		}
		label{
			//padding: $item-border;
			//position: absolute;
			padding: 0 0 100%;
			height: 0;
			margin: 0;
			z-index: 1;
			&,img{
				left: 0;
				top: 0;
				width: 100%;
			}
			img{
				position: absolute;
				height: auto;
				z-index: 0;
				border: $item-border solid $cl-white;
			}
			input{
				position: absolute;
				left: $item-padding;
				bottom: $item-padding;
			}
		}
	}
}
