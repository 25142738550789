@import "var/layout";
@import "var/const";
@import "utils";

$prod-application-width: $page-base-width / 3;

ul.product-applications{
	clear: both;
	padding: 0;
	margin: $padding-general auto;
	list-style: none;
	li{
		display: block;
		float: left;
		@include calc('width', 100% / 3);
		img{
			width: 100%;
			height: auto;
		}
	}
}