@import "wiesner-hager";

$cl-theme-color: $cl-wh-red;
$cl-theme-gray-bg: $cl-wh-gray-bg;

$font-theme-main: $font-wh-text;
$font-theme-text: $font-wh-text;

$border-radius: 0;

//$font-url:
