@import "var/const";
@import "var/layout";
@import "utils";

.product-description{
	width: 100%;
	padding: 0;
	margin: $margin-general 0;
	line-height: 1.5;
	@include respond-max-w($page-base-width + ($padding-general * 2)){
		padding: 0 $padding-general;
	}
}