@import "var/layout";
@import "utils";
@import "var/const";

fieldset#order{
	left: auto;
	top: 0;
	right: 0;
	width: auto;
	&.open{
		max-width: 400px;
		width: 100%;
	}
	.fs-content{
		max-width: 400px;
		width: 100%;
		position: absolute;
		top: $control-height;
		right: 0;
	}
}

legend.order-ctl{
	width: auto;
	float: right;
	font-size: 1rem;
	label,button{
		float: left;
	}
	button.add{
		margin-right: 1px;
	}
	label[for="add-cnt"]{
		margin: 0;
		padding: 0;
		height: $control-height;
		width: 60px;
	}
	input#add-cnt{
		width: 100%;
		border: none;
		float: left;
		padding: 0 $padding-general;
		@include center-height($control-height);
	}
}

@import "basket";
