@import "../bootstrap-config";
@import "../var/const";
@import "../utils";
@import "compass";
@import "../var/brand";
@import "../../vendor/twbs/bootstrap/scss/variables";


fieldset.fs-toggle{
	$size: $top-menu-size;
	$padding: $padding-general;

	$sizeInner: $size - $padding*2;
	position: absolute;
	top: $top-menu-offset;
	@include dynamicAlignment();

	padding: 0;
	z-index: 5;
	&>legend{
		display: block;
		border: none;
		margin: 0;
		button{
			@include border-radius($border-radius);
			//@include center-height($top-menu-size);
			//padding: 0 $padding-general;
			//width: $size;
			height: $size;
			padding: $padding;
			box-sizing: border-box;
			line-height: $sizeInner;
			img{
				width: auto;
				height: 100%;
				vertical-align: top;
				//padding: $padding-general 0;
			}

			span.label{
				line-height: $sizeInner;
				font-size: 1rem;
				display: none;
				vertical-align: bottom;
			}
		}
	}


	//z-index: 1;
	div.fs-content{
		opacity: 0;
		@include single-transition(opacity, 0.35s, ease-in-out);
		//visibility: hidden;
		width: 0;
		height: 0;
		display: block;
		overflow: hidden;
	}

	&.open{
		z-index: 6; // hide other fs-toggles
		div.fs-content{
			overflow: visible;
			@include single-transition(opacity, 0.35s, ease-in-out);
			opacity: 1;
			width: auto;
			height: auto;
		}
	}

	&.expand.open>legend,
	&.expand>legend:hover{
		button{
			padding-left: $size;
			position: relative;
			width: auto;
			img{
				position: absolute;
				left: $padding;
				top: $padding;
				//@include calc(height, )
				height: $sizeInner;
			}
			span.label{
				display: inline-block;
			}
		}
	}
}

button.fs-toggle{
	border: none;
	cursor: pointer;
	background: $cl-theme-color;
	color: $cl-white;
	&.pulse{
		@include pulse-bg($cl-theme-color)
	}
}

ul.icon-panel{
	span.icon{
		display: block;

		@include dynamic-properties((
			width: $item-size,
			height: $item-size,
			padding: $item-padding
		));
		img{
			width: 100%;
			height: 100%;
		}
	}

	li{
		.preview{
			width: auto;
			height: auto;
			max-width: 100%;
			max-height: 100%;
			margin: 0 auto;
			display: inline-block;
		}
		background: $cl-white;
		&.active{
			label{
				border-color: $cl-wh-red;
			}
		}
	}
}

ul.item-select{
	@include box-shadow(0 0 4px rgba(#000000, 0.5));
	li {
		position: relative;
		display: block;
		padding: 0;
		z-index: 2;
		@include dynamic-properties((
			width: $item-size-sm,
			height: $item-size-sm
		));
		span.preview{
			@include box-shadow(0 0 4px rgba(#000000, 0.5));
		}
		&:hover {
			@include item-hover;
		}
		label{
			display: block;
			cursor: pointer;
			width: 100%;
			height: 100%;
			margin: 0;
			padding: 0;
			color: $cl-menu-text;
			position: relative;
			text-align: center;
			input{
				position: absolute;
				@include dynamic-properties((
					bottom: $item-padding * 2,
					left: $item-padding * 2
				));

			}
		}
		&.active{
			label{
				padding-bottom: 0;
				border-bottom: $wh-active-item-border solid $cl-wh-red;
			}
		}
	}
}
