$cl-wh-black: rgb(0, 0, 0);
$cl-wh-red: rgb(227, 6, 19);
$cl-wh-gray: rgb(62, 86, 96);
$cl-wh-gray-bg: #f6f6f6;
$cl-wh-silver: silver;

$cl-wh-head: rgb(62, 86, 96);
$cl-wh-text: rgb(87, 87, 86);

$cl-wh-footer: $cl-wh-gray-bg;

//$font-wh-h1: 'Slab TallX', Georgia, serif;
$font-wh-h1: 'Rockwell W01', Georgia, serif;
$font-wh-h2: 'FrutigerLTW02-55Roman', Arial, Helvetica, sans-serif;
//$font-wh-text: 'Frutiger 47', Arial, Helvetica, sans-serif;
$font-wh-text: 'FrutigerLTW02-45Light', Arial, Helvetica, sans-serif;
$font-wh-text-i: 'FrutigerLTW02-46LightIt', Arial, Helvetica, sans-serif;

$wh-active-item-border: 5px;
