@import "var/const";
@import "var/brand";

$breadcrumb-bg: trasparent;

//$btn-primary-bg: $cl-wh-red;
$brand-primary: $cl-wh-red;

$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;

$font-family-base: $font-theme-main;
