@import "../utils";

@mixin rounded-ctl($sz){
	$spacing: $sz * 0.1;
	$spacing-ico: $sz * 0.2;
	$ico-sz: $sz - $spacing-ico * 2;
	box-sizing: content-box;
	width: $sz;
	height: 2* $sz + $spacing;
	border-width: $spacing;
	border-radius: $sz/2 + $spacing;
	button{
		width: $sz;
		height: $sz;
		border-radius: $sz/2;
		.ico{
			top: $spacing-ico;
			left: $spacing-ico;
			width: $ico-sz;
			height: $ico-sz;
			background-size: $ico-sz $ico-sz *2;
		}
	}
}
.ctl-zoom{
	$spacing: 5px;
	$bg-ctl: rgba(#000000, 0.2);
	//$bg-ctl: #cfcfcf;
	$bg-btn: rgba(#ffffff, 0.8);
	position: absolute;
	top: $spacing;
	right: $spacing;
	border: solid transparent;
	background: $bg-ctl;
	display: block;
	button{
		&, .ico{
			display: block;
			position: absolute;
		}
		.ico{
			@include svg-bg('ctl/ctl-zoom');
			&.ico-plus{
				background-position: center top;
			}
			&.ico-minus{
				background-position: center bottom;
			}
		}
		padding: 0;
		left: 0;
		border: none;
		line-height: 1000px;
		overflow: hidden;
		box-shadow: 0 0 5px rgba(#000000, 0.2);
		&.pressed{
			box-shadow: 0 0 5px rgba($cl-active, 0.8);
		}
		&.zoom-in{
			top: 0;
		}
		&.zoom-out{
			bottom: 0;
		}
	}
	@include rounded-ctl(50px);
}
