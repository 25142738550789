@import "var/const";
@import "var/layout";
@import "compass";

$is-plugin: false !default;
$pulseAnimations: () !default;

$layout-limit-x: 3;
$layout-limit-y: 2;

@mixin respond-max-w($media: $page-base-width) {
	@if type-of($media) == 'number' {
		$media: 'screen and (max-width: '+$media+')';
	}
	@media #{$media} {
		@content;
	}
}

@mixin respond-max-h($media: $page-base-width) {
	@if type-of($media) == 'number' {
		$media: 'screen and (max-height: '+$media+')';
	}
	@media #{$media} {
		@content;
	}
}

@mixin respond-max($media: $page-base-width) {
	@if map-has-key($scales, $media) {
		$media: nth(map-get($scales, $media), 1);
	}

	@if type-of($media) == 'number' {
		$media: 'screen and (max-width: '+$media+'), screen and (max-height: '+$media+')';
	}
	@media #{$media} {
		@content;
	}
}

@mixin vertical-layout(){
	@media screen and (max-aspect-ratio: 1/1){
		@content;
	}
}

@mixin horizontal-layout(){
	@media screen and (min-aspect-ratio: 1/1){
		@content;
	}
}

@mixin svg-bg($name, $color: transparent, $position: 0 0, $repeat: no-repeat) {
	background: $color url('../img/#{$name}.png') $position $repeat;
	background-image: none, url('../img/#{$name}.svg');
}

@mixin center-height($height, $autoRespond: false){
	height: $height;
	line-height: $height;
}

@mixin dynamic-properties($map){
	@each $prop, $val in $map {
		#{$prop}: $val;
	}
	@each $r, $respo in $scales{
		@include respond-max(nth($respo, 1)){
			@each $prop, $val in $map{
				#{$prop}: $val * nth($respo, 2)
			}
		}
	}
}

@mixin dynamic-properties-callback($map){
	@each $prop, $callback in $map {
		#{$prop}: call($callback, 1);
	}
	@each $r, $respo in $scales{
		@include respond-max(nth($respo, 1)){
			@each $prop, $callback in $map{
				#{$prop}: call($callback, nth($respo, 2))
			}
		}
	}
}

@mixin item-hover{
	@include box-shadow();
	z-index: 10;
}

@mixin calc($property, $expression) {
	#{$property}: -webkit-calc(#{$expression});
	#{$property}: calc(#{$expression});
}

@mixin n-elements($cnt, $margin){
	&:nth-child(#{$cnt}){
		margin-right: 0;
	}
	@include calc(width, '(100% - ' + (($cnt - 1) * $margin) + ') / ' + $cnt);
}

@mixin pulse-bg($color1, $color2: null, $loop: 2.5s){
	@if $color2 == null {
		$color2: lighten($color1, 10%);
		$color1: darken($color1, 10%);
	}
	$pulseId: 'pulse-' + str-slice(inspect($color1), 2) + '-' + str-slice(inspect($color2), 2);
	@if not index($pulseAnimations, $pulseId){
		$pulseAnimations: append($pulseAnimations, $pulseId) !global;
		@at-root {
			@keyframes #{$pulseId} {
				0%, 100% {
					@include box-shadow(0 0 10px $cl-white);
					background-color: $color1;
				}
				50% {
					@include box-shadow(0 0 10px $color2);
					background-color: $color2;
				}
			}
		}
	}
	animation: #{$pulseId} $loop infinite;
}

@function sqr($f){
	@return $f * $f;
}

@mixin dynamicAlignment(){
	left: 0;
	@if $is-plugin==false {
		@at-root .controll-position-right #{&} {
			left: auto;
			right: 0;
			& > legend {
				text-align: right;
			}
		}
	}
	@else{
		$selector: str-slice(#{&}, str-index(#{&}, ' '));
		@at-root .Mathesio3DApp.controll-position-right #{$selector} {
			left: auto;
			right: 0;
			& > legend {
				text-align: right;
			}
		}
	}
}
