section#page-menu{
	ol.breadcrumb{
		float: left;
	}
	div.lang-switch{
		float: right;
		label{
			cursor: pointer;
		}
	}
}
