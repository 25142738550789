$padding-general: 9px;
$margin-general: 30px;

$screen-item-size: 300px;
$screen-item-width: $screen-item-size;
$screen-item-height: $screen-item-size;
$screen-item-padding: $padding-general;

$screen-item-count: 4;
$page-base-width: ($screen-item-width * $screen-item-count + $screen-item-padding * ($screen-item-count - 1));

$breadcrumb-height: 35px;
$breadcrumb-padding: $padding-general;
