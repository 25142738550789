/**
 * General rules to be used both in plugin and application
 */

/** VARIABLES **/
$is-plugin: false !default;
@import "var/const";
@import "utils";
@import "compass";
@import "var/brand";
@import "var/layout";

@import "bootstrap-config";

@import "../vendor/twbs/bootstrap/scss/bootstrap";
@import "bootstrap-patch";

$font-url: '' !default;
@if ($font-url) {
	@import url($font-url);
}

/** RULES **/
.body-block{
	width: 100%;
	max-width: $page-base-width;
	margin: 0 auto;
}

// basic container including GUI and 3D View
.app3d{
	width: 100%;
	height: 100vh;
	max-height: 100vw;
	//border: 1px solid $cl-black;
	//background: $cl-controls;
	margin: auto;
	position: relative;
	box-sizing: content-box;
	//overflow: hidden;
}

#offer-form, #background{
	width: 100%;
	height: 100%;
}

hr{
	clear: both;
}

/** ADDITIONAL ICLUDED RULES **/
@import "look-and-feel";
@import "3d-view/3D-gui";
@import "top-panel";  // product select
@import "general";  // price
