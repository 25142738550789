$is-plugin: false !default;
// General styles
@import "var/brand";
@import "var/const";
@import "var/layout";
@import "utils";

@if $is-plugin == true{
	@at-root {
		.Mathesio3DApp{
			font: 11pt $font-theme-text;
		}
	}
}
@else{
	html, body{
		width: 100%;
		height: 100%;
		font: 11pt $font-wh-text;
		font-family: $font-theme-text;
	}
	body {
		margin: 0; background: $cl-white;
	}
}

h1{
	font: 2.3rem $font-wh-h1;
	text-transform: uppercase;
	@include respond-max-w($page-base-width + ($padding-general * 2)){
		padding: 0 $padding-general;
	}
}

a{
	color: $cl-wh-black;
	&, &:hover{
		text-decoration: none;
	}
	&:hover{
		color: $cl-wh-red;
	}
}

hr.separator{
	border-color: $cl-wh-silver;
	margin: 0;
}

ol.breadcrumb{
	padding: $padding-general 0;
	margin: 0;
	background: transparent;
	a{
		text-transform: uppercase;
		display: inline-block;
		@include center-height($breadcrumb-height);
		padding: 0 $breadcrumb-padding;
	}
	&> li + li::before{
		padding: 0;
		content: "/";
	}
}
