@import "../../var/brand";
@import "../../var/const";
@import "../../var/layout";
@import "../../utils";

&.product-property-scroll{

	ul.product-property-icon{
		li{
			display: none;
			&.active{
				display: block;
			}
			label {
				display: block;
				background: $cl-theme-color;
			}
		}
	}
}
