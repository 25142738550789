@import "var/const";
@import "var/layout";
@import "var/brand";
@import "compass/css3";
@import "utils";

$line-height: 18px;
$line-height-sm: 14px;
$table-padding: $padding-general;

table.order{
	width: 100%;
	background: $cl-white;
	color: $cl-wh-gray;
	td, th{
		padding: $table-padding;
		@include center-height($line-height);
	}
	td,th{
		font-weight: normal;
		label, input{
			font-weight: normal;
			margin: 0;
			padding: 0;
			width: 100%;
			height: 100%;
			@include center-height($line-height);
		}
	}
	td.cnt{
		width: $line-height * 2 + $table-padding * 2;
		padding: 0 $padding-general;
		input{
			border: none;
			@include center-height($line-height + $table-padding * 2)
		}
	}
	td.price{
		width: 25%;
	}
	td.ctl{
		$btn-size: $table-padding * 2 + $line-height;
		padding: 0;
		width: $btn-size;
		button{
			display: block;
			padding: 0;
			border: none;
			color: $cl-white;
			line-height: $line-height;
			height: $btn-size;
			width: $btn-size;
			&.remove{
				background: $cl-wh-red;
			}
		}
	}
	tr.article{
		display: none;
		&.used{
			display: table-row;
		}
	}
	tr.total{
		td, th{
			border-top: 1px solid $cl-wh-silver;
			font-weight: bold;
		}
	}

	tr.detail > td{
		padding: 0 0 $padding-general $padding-general;
	}

	table.detail{
		width: 100%;
		color: lighten($cl-wh-gray, 20%);
		th, td{
			padding: $padding-general / 2 $padding-general;
			line-height: $line-height-sm;
			font-size: 0.9em;
		}
		tr:first-child{
			th, td{
				padding-top: 0;
			}
		}
		tr:last-child{
			th, td{
				padding-bottom: 0;
			}
		}
	}

	@include box-shadow();
}
