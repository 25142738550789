@import "compass";
@import "brand";

$cl-black: #000000;
$cl-white: #ffffff;

$cl-active: $cl-theme-color;
$cl-controls: $cl-white;

$cl-menu-background: $cl-theme-gray-bg;
$cl-menu-highlight: lighten($cl-theme-color, 15%);
$cl-menu-active: $cl-theme-color;
$cl-menu-text: $cl-black;
$cl-button-bg: $cl-theme-color;

$size-win: 800px !default;
$size-ctl: 300px !default;

$side-padding: 5px !default;
$layout-padding: 10px !default;
$price-height: 60px !default;

$top-menu-offset: 0px !default;
$top-menu-size: 40px !default;
$control-height: 40px !default;
$item-padding: 3px !default;
$item-size: 128px !default;

// fallback default values - to be replaced with actual preview size
$material-item-size: 64;
$connection-item-w: 64;
$connection-item-h: 64;

//$material-item-size: image-width('../www/data/material/White.png');
//$connection-item-w: image-width('../www/data/connections/1L.png');
//$connection-item-h: image-height('../www/data/connections/1L.png');

// Subitems
$item-size-sm: $item-size;

$scales: (
	medium: (920px, 0.8),
	small: (800px, 0.6),
	tiny: (550px, 0.4)
);

$breakdowns: (
	small: (600px, 0.8),
	tiny: (300px, 0.6)
);
$menupos-breakdown: 410px;

@function breakdown-get($scale){
	@return map-get($breakdowns, $scale);
}
